<template>
  <NuxtLink v-if="route" :to="localePath((route as RouteLocation), locale)">
    <slot></slot>
  </NuxtLink>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import type {
  RouteLocationPathRaw,
  RouteLocationNamedRaw,
  RouteLocation
} from 'vue-router'

const props = withDefaults(
  defineProps<{
    withBranchIdQuery?: boolean
    to: string | RouteLocationPathRaw | RouteLocationNamedRaw | undefined
    locale?: string
    branchId?: number
  }>(),
  {
    locale: undefined,
    withBranchIdQuery: true,
    branchId: undefined
  }
)
const route = computed(() => {
  let routeLocal = props.to
  const branchId = props.branchId || useBranchId()
  if (props.withBranchIdQuery && routeLocal) {
    if (typeof routeLocal === 'string') {
      routeLocal = {
        path: routeLocal,
        query: { branch_id: branchId }
      }
    } else {
      routeLocal.query = { ...routeLocal.query, branch_id: branchId }
    }
  }

  return routeLocal
})
const localePath = useLocalePath()
</script>
